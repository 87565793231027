import Skeleton from '@mui/material/Skeleton'
import React from 'react'
import Carousel from '../Carousel/Carousel'
import {
  StyledSkeleton,
  StyledSpotCard,
  StyledSpotCardContainer,
  StyledSpotCardTitleContainer,
  StyledTopLabel
} from '../LocationCard/LocationCards.styled'

export function LoadingCard() {
  return (
    <StyledSpotCardContainer fullwidth={0}>
      <StyledSpotCard sx={{ height: '100px' }}>
        <StyledTopLabel>
          <Skeleton animation="wave" width={60} height={10} sx={{ mr: 1 }} />
          <Skeleton
            variant="circular"
            animation="wave"
            width={35}
            height={35}
            sx={{ mr: 0.1, mt: 0.1 }}
          />
        </StyledTopLabel>

        <StyledSkeleton variant="rectangular" animation="wave" />
      </StyledSpotCard>
      <StyledSpotCardTitleContainer>
        <Skeleton width="70%" height={40} />
        <Skeleton width="40%" sx={{ mt: -1 }} />
      </StyledSpotCardTitleContainer>
    </StyledSpotCardContainer>
  )
}

function LoadingRecommendedCard({ count = 5 }: { count?: number }) {
  const cards = Array.from(Array(count).keys())

  return (
    <Carousel
      items={cards}
      carouselOptions={{ loop: true }}
      carouselOptionsDesktop={{ align: 'start' }}
      showButtonsMd
      showDotsMd
      marginButtons={4}
    >
      {({ item }) => <LoadingCard key={item} />}
    </Carousel>
  )
}

export default React.memo(LoadingRecommendedCard)
