/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import Card from '@mui/material/Card'
import Skeleton from '@mui/material/Skeleton'
import { BorderRadius, Colors, FontWeight } from '../../constants/theme'

export const StyledSkeleton = styled(Skeleton)`
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  border-top-right-radius: ${BorderRadius.xLarge}px;
  border-top-left-radius: ${BorderRadius.xultra}px;
  border-bottom-left-radius: ${BorderRadius.xultra}px;
  border-bottom-right-radius: ${BorderRadius.xultra}px;
`

export const StyledSpotCardContainer = styled.div<{
  fullwidth: number
  heightmb?: string
  heightmd?: string
}>`
  display: flex;
  position: relative;
  flex-direction: column;

  width: ${({ fullwidth }) => (fullwidth ? '100%' : '278px')};
  height: ${({ heightmb }) => (heightmb ? heightmb : '180px')};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: ${({ fullwidth }) => (fullwidth ? '100%' : '278px')};
    height: ${({ heightmd }) => (heightmd ? heightmd : '200px')};
  }

  overflow: hidden;

  text-decoration: none;
  isolation: isolate;

  /* Styles required to target anchor and put it up front of the container but invicible */
  & > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }
`

export const StyledSpotCard = styled(Card)`
  && {
    position: relative;
    border-top-right-radius: ${BorderRadius.xLarge}px;
    border-top-left-radius: ${BorderRadius.xultra}px;
    border-bottom-left-radius: ${BorderRadius.xultra}px;
    border-bottom-right-radius: ${BorderRadius.xultra}px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: ${Colors.grey300};
    overflow: hidden;
    ::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: ${Colors.gradient400};
    }
  }
`

const StyledLabelBase = styled.span`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: auto;
  height: 30px;
  color: ${({ theme }) => theme.palette.lightest.main};
  font-size: 15px;
  font-weight: ${FontWeight.extraBold};
  line-height: 14px;
  z-index: 1;
`

export const StyledTopLabel = styled(StyledLabelBase)`
  && {
    top: 0;
    right: 0;
    border-bottom-right-radius: 20px;
    font-weight: ${FontWeight.medium};

    ${({ theme }) => theme.breakpoints.up('md')} {
      #name {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
`

export const StyledBottomLabel = styled.div`
  && {
    font-weight: ${FontWeight.bold};
    display: flex;
    justify-content: space-between;
    strong {
      color: ${Colors.primary};
      font-weight: ${FontWeight.extraBold} !important;
      font-size: 15px;
    }
  }
`

export const StyledMidLabel = styled.div`
  background-color: ${Colors.primary};
  width: max-content;
  padding: 2px 10px !important;
  margin-left: -5px;
  border-top-left-radius: ${BorderRadius.xLarge}px;
  border-bottom-left-radius: ${BorderRadius.xLarge}px;
  border-top-right-radius: ${BorderRadius.xLarge}px;
  border-bottom-right-radius: ${BorderRadius.xLarge}px;
`

export const StyledSpotCardTitleContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border-top-right-radius: ${BorderRadius.large}px;
  border-bottom-left-radius: ${BorderRadius.ultra}px;
  border-top-left-radius: ${BorderRadius.ultra}px;
  border-bottom-right-radius: ${BorderRadius.ultra}px;
  padding: 3px 30px;
  flex-direction: column;
  gap: 3px;

  && h6 {
    font-weight: ${FontWeight.bold};
    font-size: 13px;
  }
  && h3 {
    font-size: 14px;
  }
  && span {
    bottom: -6px;
  }
`
